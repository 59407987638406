import { InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useBaseFetch from '../hooks/useBaseFetch'
import { APIError, Paginated } from '../types/common'
import {
  Conversation,
  ConversationDetail,
  ConversationMessage,
  ConversationMessageFeedback,
  CreateConversationInput,
  InfiniteConversationsParams,
} from '../types/conversation'

export const QK_WELCOME_MESSAGE = '/conversations/message/welcome'
export const QK_CONVERSATIONS = '/conversations'
export const QK_CONVERSATION = '/conversations/:id'

export const useGetWelcomeMessage = () => {
  const fetch = useBaseFetch()

  return useQuery<Paginated<ConversationMessage>>({
    queryKey: [QK_WELCOME_MESSAGE],
    queryFn: () => fetch.get(QK_WELCOME_MESSAGE),
    staleTime: Infinity,
  })
}

export const useGetConversations = () => {
  const fetch = useBaseFetch()

  return useQuery<Paginated<Conversation>>({
    queryKey: [QK_CONVERSATIONS],
    queryFn: () => fetch.get(QK_CONVERSATIONS),
  })
}

export const useCreateConversation = () => {
  const fetch = useBaseFetch()
  const client = useQueryClient()

  return useMutation<Conversation, APIError, CreateConversationInput | undefined>({
    mutationFn: (data) => {
      return fetch.post(QK_CONVERSATIONS, data)
    },
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: [QK_CONVERSATIONS],
      })
    },
  })
}

export const useGetConversationById = (params: InfiniteConversationsParams) => {
  const fetch = useBaseFetch()
  const { conversationId, ...rest } = params

  return useInfiniteQuery<ConversationDetail>({
    queryKey: [QK_CONVERSATION, params],
    queryFn: ({ pageParam }) =>
      fetch.get(`/conversations/${conversationId}`, {
        ...rest,
        page: pageParam,
      }),
    getNextPageParam: (data) => {
      if (data.pagination.next_page !== data.pagination.current_page) {
        return data.pagination.next_page
      }
    },
    initialPageParam: 1,
    enabled: !!conversationId,
    staleTime: 5000,
  })
}

export const useVoteMessage = (conversationId: string, messageId: string) => {
  const fetch = useBaseFetch()
  const client = useQueryClient()

  return useMutation<Conversation, APIError, ConversationMessageFeedback | null>({
    mutationFn: async (feedback) => fetch.put(`/conversations/feedback/${conversationId}/${messageId}`, { feedback }),
    onMutate: (feedback) => {
      const current = client.getQueriesData<InfiniteData<ConversationDetail>>({
        queryKey: [QK_CONVERSATION],
      })

      current.forEach(([key, prev]) => {
        client.setQueryData<InfiniteData<ConversationDetail>>(
          key,
          prev
            ? {
                ...prev,
                pages: prev.pages.map((page) => ({
                  ...page,
                  messages: page.messages.map((el) => {
                    if (el.id === messageId) {
                      return {
                        ...el,
                        feedback,
                      }
                    }

                    return el
                  }),
                })),
              }
            : undefined,
        )
      })
    },
    onSuccess: async () => {
      client.invalidateQueries({
        queryKey: [QK_CONVERSATIONS],
      })
    },
  })
}
