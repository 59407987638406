import * as Sentry from '@sentry/nextjs'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import { createContext, FC, ReactNode, useCallback, useContext, useRef, useState } from 'react'
import { QK_CONVERSATION, QK_CONVERSATIONS } from '../api/conversations'
import { useToast } from '../components/Toast/ToastProvider'
import {
  BodyPartText,
  ConversationDetail,
  ConversationMessage,
  ConversationMessageBodyPart,
  ConversationSenderType,
  StreamingMessage,
} from '../types/conversation'
import { IS_SANDBOX } from '../utils/const'
import { log } from '../utils/log'
import { useConversationContext } from './ConversationProvider'
import { useTokenContext } from './TokenProvider'

const useContextHook = () => {
  const { token } = useTokenContext()
  const { assistantId, conversationId } = useConversationContext()
  const client = useQueryClient()
  const { toastError } = useToast()

  const contentRef = useRef<HTMLDivElement>(null)
  const [typing, setTyping] = useState(false)
  const [streamingMessages, setStreamingMessages] = useState<ConversationMessage>()
  const [streamReader, setStreamReader] = useState<ReadableStreamDefaultReader>()

  const handleStream = useCallback(
    async (body: string) => {
      setTyping(true)

      window.requestAnimationFrame(() => {
        contentRef.current?.scroll({
          top: 99999999,
        })
      })

      const current = client.getQueriesData<InfiniteData<ConversationDetail>>({
        queryKey: [QK_CONVERSATION],
      })

      current.forEach(([key, prev]) => {
        if ((key as any)[1].conversationId === conversationId) {
          client.setQueryData<InfiniteData<ConversationDetail>>(
            key,
            prev
              ? {
                  ...prev,
                  pages: prev.pages.map((page, index) => ({
                    ...page,
                    messages:
                      // optimistically add latest message to the first page
                      index === 0
                        ? [
                            {
                              id: new Date().toISOString(),
                              message_type: 'user_text_message',
                              timestamp: new Date().toISOString(),
                              sender_type: ConversationSenderType.Human,
                              body_parts: [
                                {
                                  body_part_type: 'text',
                                  text: body,
                                },
                              ],
                            },
                            ...page.messages,
                          ]
                        : page.messages,
                  })),
                }
              : undefined,
          )
        }
      })

      const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/conversations/stream/${conversationId}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'Accept-language': 'en-US',
          Authorization: `Bearer ${token}`,
          'X-assistant-id': assistantId,
        },
        body: JSON.stringify({
          body,
          message_type: 'user_text_message',
        }),
      })

      setTyping(false)

      const scroll = (behavior?: ScrollBehavior) => {
        // if (
        //   contentRef.current &&
        //   contentRef.current.scrollHeight - contentRef.current.scrollTop - contentRef.current.clientHeight < 100
        // ) {
        window.requestAnimationFrame(() => {
          contentRef.current?.scroll({
            top: 99999999,
            behavior,
          })
        })
        // }
      }

      const reader = response.body?.getReader()
      const parts: ConversationMessageBodyPart[] = []

      if (reader) {
        setStreamReader(reader)

        try {
          while (true) {
            const { value, done } = await reader.read()
            const res = new TextDecoder().decode(value)
            const tokens = res.split('\n')

            tokens.forEach((el) => {
              if (!el) return

              const matches = el.split('\n')

              matches?.forEach((item) => {
                try {
                  const stream: StreamingMessage = JSON.parse(item)
                  // console.log(stream)

                  // <tool>
                  // <message_start>
                  // <text>
                  // <text>
                  // <source1>
                  // <source2>
                  // <message_start>
                  // <text>

                  // new block, reset things
                  if (stream.body_part_type === 'message_start') {
                    parts.push({
                      body_part_type: 'text',
                      text: '',
                    })
                    // text block, concat with the last item
                  } else if (stream.body_part_type === 'text') {
                    const previousPart = parts[parts.length - 1] as BodyPartText
                    previousPart.text += stream.text
                    // the rest can be added as a new part
                  } else {
                    parts.push(stream)
                  }

                  // stream has finished
                  if (stream.data) {
                    const current = client.getQueriesData<InfiniteData<ConversationDetail>>({
                      queryKey: [QK_CONVERSATION],
                    })

                    current.forEach(([key, prev]) => {
                      if ((key as any)[1].conversationId === conversationId) {
                        client.setQueryData<InfiniteData<ConversationDetail>>(
                          key,
                          prev
                            ? {
                                ...prev,
                                pages: prev.pages.map((page, index) => ({
                                  ...page,
                                  messages: index === 0 ? [...stream.data.reverse(), ...page.messages] : page.messages,
                                })),
                              }
                            : undefined,
                        )
                      }
                    })

                    setStreamingMessages(undefined)
                    scroll()

                    setTimeout(() => {
                      scroll()
                    }, 150)

                    // send streaming data
                  } else {
                    setStreamingMessages({
                      id: Math.random().toString(),
                      timestamp: new Date().toISOString(),
                      sender_type: ConversationSenderType.Bot,
                      message_type: 'ai_text_answer',
                      body_parts: parts,
                    })
                    scroll()
                  }
                } catch (e: any) {
                  log(e)
                }
              })
            })

            if (done) {
              break
            }
          }
        } catch (e: any) {
          toastError(e.message)
          setStreamingMessages(undefined)

          if (!IS_SANDBOX) {
            Sentry.addBreadcrumb({
              category: 'log',
              message: `Stream error! Message: ${body}`,
              level: 'info',
            })
            Sentry.captureException(e)
          }
        }

        setTimeout(() => {
          scroll('smooth')
        }, 150)

        client.invalidateQueries({
          queryKey: [QK_CONVERSATIONS],
        })

        setStreamReader(undefined)
      } else {
        toastError('No reader from stream')

        if (!IS_SANDBOX) {
          Sentry.addBreadcrumb({
            category: 'log',
            message: `No reader from stream! Message: ${body}`,
            level: 'info',
          })
        }
      }
    },
    [assistantId, contentRef, client, conversationId, token],
  )

  const handleCancel = async () => {
    streamReader?.cancel()

    const current = client.getQueriesData<InfiniteData<ConversationDetail>>({
      queryKey: [QK_CONVERSATION],
    })

    current.forEach(([key, prev]) => {
      if ((key as any)[1].conversationId === conversationId) {
        client.setQueryData<InfiniteData<ConversationDetail>>(
          key,
          prev
            ? {
                ...prev,
                pages: prev.pages.map((page, index) => ({
                  ...page,
                  messages:
                    index === 0
                      ? [
                          {
                            id: new Date().toISOString(),
                            message_type: 'ai_text_answer',
                            timestamp: new Date().toISOString(),
                            sender_type: ConversationSenderType.Bot,
                            body_parts: streamingMessages?.body_parts || [],
                          },
                          ...page.messages,
                        ]
                      : page.messages,
                })),
              }
            : undefined,
        )
      }
    })

    setStreamingMessages(undefined)
    client.invalidateQueries({
      queryKey: [QK_CONVERSATIONS],
    })
  }

  return {
    contentRef,
    typing,
    setTyping,
    streamingMessages,
    handleStream,
    handleCancel,
  }
}

type StorageHook = ReturnType<typeof useContextHook>

const StreamingContext = createContext<StorageHook>({} as any)

type Props = {
  children?: ReactNode
}

const StreamingProvider: FC<Props> = ({ children }) => {
  const props = useContextHook()
  return <StreamingContext.Provider value={props}>{children}</StreamingContext.Provider>
}

export const useStreamingContext = () => useContext(StreamingContext)

export default StreamingProvider
